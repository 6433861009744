import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import CardCollection from '@/components/Cards/CardCollection.vue'
import { Collection } from '@/Models/Collection'
import * as toast from '@/services/toast'
import { httpMulti } from '@/services/http'
import TermsConditions from '@/components/Terms&Conditions/index.vue'
import authModule from '@/store/auth'
import ExpandableButton from '@/components/ExpandableButton/index.vue'
import BreadCrumbModule from '@/store/moduleBreadCrumb'
import Animation from '@/components/Animation/index.vue'
import menuModule from '@/store/menu'

@Component({
  components: {
    Animation,
    ValidationObserver,
    ValidationProvider,
    CardCollection,
    TermsConditions,
    ExpandableButton,
  },
})
export default class Index extends Vue {
  isLoading = false
  active = ''
  activeDelete = ''
  localStorage = ''
  collection_new = {
    card_color: '#cecece',
    card_icon: null,
    user_id: authModule.user.user_id,
  } as Collection
  collection_array: Collection[] = []

  async mounted() {
    menuModule.setLoding(true)

    BreadCrumbModule.clear()
    await this.getCollections()
    menuModule.setLoding(false)
  }

  selectDefaultTenant() {
    const BASE_URL = process.env.VUE_APP_API_URL
    const BASE_PROTOCOL = process.env.VUE_APP_API_PROTOCOL
    const getUrl = window.location
    const protocol = getUrl.protocol
    const url = getUrl.origin.replace(protocol + '//', '')
    const TENANT = url.split('.').length === 1 ? '' : url.split('.')[0] + '.'
    return `${BASE_PROTOCOL}://${TENANT}${BASE_URL}`
  }

  async getCollections() {
    try {
      const res = await httpMulti.get('hoshin/collection/')
      this.collection_array = res.data.map((collection: any) => ({
        ...collection,
        card_icon:
          this.selectDefaultTenant() +
          collection.card_icon.substring(
            collection.card_icon.indexOf('uploaded_files'),
          ),
      }))
      console.log(this.collection_array, 'SSSSSD')
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  rgbToHex(r: number, g: number, b: number) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
  }

  async saveData() {
    menuModule.setLoding(true)
    try {
      const formData = new FormData()
      const color = this.$refs.color as any
      const color_selected = color.colorSelected
      this.collection_new.card_color = this.rgbToHex(
        Number(color_selected.red),
        Number(color_selected.green),
        Number(color_selected.blue),
      )
      Object.keys(this.collection_new).forEach((key: string) => {
        if (key !== 'card_icon' || this.collection_new.card_icon)
          formData.append(key, (this.collection_new as any)[key])
      })
      const res = await httpMulti.post('hoshin/collection/', formData)
      this.collection_array.push(res.data as Collection)
      this.showModal()
    } catch (error: any) {
      if (
        error.response.status &&
        error.response.status == 400 &&
        error.response.data.name &&
        error.response.data.name[0] &&
        error.response.data.name[0] == 'Ya existe Collection con este name.'
      )
        toast.error('Esta collección ya existe.')
      else toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
    menuModule.setLoding(false)
  }

  showModal() {
    this.active = this.active ? '' : 'is-active'
    if (this.active) {
      this.collection_new = {
        card_color: '#cecece',
        card_icon: null,
        user_id: authModule.user.user_id,
      } as Collection
      const img: any = document.getElementById('thumbnail')
      img.file = null
      const input = this.$refs.collection_name as any
      const observer_collection = this.$refs.observer_collection as any
      if (input) input.focus()
      if (observer_collection) observer_collection.reset()
    }
  }

  async deleteCollection(collection: Collection) {
    try {
      await httpMulti.delete(`hoshin/collection/${collection.id}/`)
      this.collection_array = this.collection_array.filter(
        (c: Collection) => c.id !== collection.id,
      )
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  async updateCollection(collection: Collection) {
    menuModule.setLoding(true)
    try {
      const formData = new FormData()
      Object.keys(collection).forEach((key: string) => {
        if (key !== 'card_icon' || collection.card_icon)
          formData.append(key, (collection as any)[key])
      })
      await httpMulti.patch(
        'hoshin/collection/' + collection.id + '/',
        formData,
      )
      await this.getCollections()
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
    menuModule.setLoding(false)
  }

  showMyImage() {
    const imga: any = document.getElementById('file_image')
    const files = imga.files
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const imageType = /image.*/
      if (!file.type.match(imageType)) {
        continue
      }

      const img: any = document.getElementById('thumbnail')
      if (img) {
        img.file = file
        const reader = new FileReader()
        reader.onload = (function (aImg) {
          return function (e: any) {
            aImg.src = e.target.result
          }
        })(img)
        reader.readAsDataURL(file)
        this.collection_new.card_icon = file
      }
    }
  }
}
