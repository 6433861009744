import { ToastProgrammatic as Alert } from 'buefy'

export enum ToastType {
  Black = 'is-black',
  Danger = 'is-danger',
  Dark = 'is-dark',
  Info = 'is-info',
  Light = 'is-light',
  Primary = 'is-primary',
  Success = 'is-success',
  Warning = 'is-warning',
  White = 'is-white',
}

export enum ToastPosition {
  Bottom = 'is-bottom',
  BottomLeft = 'is-bottom-left',
  BottomRight = 'is-bottom-right',
  Top = 'is-top',
  TopLeft = 'is-top-left',
  TopRight = 'is-top-right',
}

export function toast(
  message: string,
  duration: number,
  position: ToastPosition,
  type: ToastType,
): void {
  Alert.open({
    duration,
    message,
    position,
    type,
  })
}

export function info(
  message: string,
  duration = 5000,
  position = ToastPosition.Bottom,
): void {
  toast(message, duration, position, ToastType.Info)
}

export function success(
  message: string,
  duration = 5000,
  position = ToastPosition.Bottom,
): void {
  toast(message, duration, position, ToastType.Success)
}

export function error(
  message: string,
  duration = 5000,
  position = ToastPosition.Bottom,
): void {
  toast(message, duration, position, ToastType.Danger)
}

export function warning(
  message: string,
  duration = 5000,
  position = ToastPosition.Bottom,
): void {
  toast(message, duration, position, ToastType.Warning)
}
