import { Component, Vue, Prop } from 'vue-property-decorator'
import authModule from '@/store/auth'
import http from '@/services'

@Component({
  components: {},
})
export default class TermsConditions extends Vue {
  mounted() {
    //this.terms_conditions = authModule.user['terms_conditions']
  }

  get terms_conditions() {
    return authModule.user['terms_conditions']
  }

  async confirmTerms() {
    try {
      await http.post(`panel/terms/`)
      // const user = authModule.user
      // user.terms_conditions = true
      // console.log(user)
      // authModule.SET_USERDATA(user)
      await authModule.refresh()
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: '',
        type: 'is-danger',
      })
    }
  }
}
